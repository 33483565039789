import '../styles/index.scss';
import 'classlist-polyfill';
import logo from '../img/agromajbau-logo.png';
import entry from '../img/agromajbau-entry.png';
import SmoothScroll from 'smooth-scroll';
import zoom from 'zoom-vanilla.js';
import Filterizr from 'filterizr';
import 'cookie-notice';
/* Images */
// Favicon
import fav from '../img/Favicon.png';

// innenrenovierung
import in1 from '../img/galerie/innenrenovierung/Bad-Vorher.jpg';
import in2 from '../img/galerie/innenrenovierung/Bad-Nachher.jpg';
import in5 from '../img/galerie/innenrenovierung/Sauna-Vorher.jpg';
import in6 from '../img/galerie/innenrenovierung/Sauna-Nachher.jpg';
import in7 from '../img/galerie/innenrenovierung/Sport-Vorher.jpg';
import in8 from '../img/galerie/innenrenovierung/Sport-Nachher.jpg';
import in9 from '../img/galerie/innenrenovierung/Innentreppe-Vorher.jpg';
import in10 from '../img/galerie/innenrenovierung/Innentreppe-Nachher.jpg';

// aussenrenovierung
import au1 from '../img/galerie/aussenrenovierung/Aussenfassade-Vorher.jpg';
import au2 from '../img/galerie/aussenrenovierung/Aussenfassade-Vorher2.jpg';
import au3 from '../img/galerie/aussenrenovierung/Aussenfassade-Nachher.jpg';
import au4 from '../img/galerie/aussenrenovierung/Aussenfassade-Nachher2.jpg';
import au5 from '../img/galerie/aussenrenovierung/Aussenmauer-Vorher.jpg';
import au6 from '../img/galerie/aussenrenovierung/Aussenmauer-Nachher.jpg';
import au7 from '../img/galerie/aussenrenovierung/Aussentreppe-Vorher.jpg';
import au8 from '../img/galerie/aussenrenovierung/Aussentreppe-Vorher2.jpg';
import au9 from '../img/galerie/aussenrenovierung/Aussentreppe-Nachher.jpg';
import { type } from 'os';

/* ================== Services ==================*/
var serviceToggles = document.querySelectorAll('.services-toggle');

serviceToggles.forEach((serviceToggle) => {
    serviceToggle.addEventListener("click", function(){
        var unorderedList = serviceToggle.parentElement.nextElementSibling;
        serviceToggle.classList.toggle("open");
        unorderedList.classList.toggle("open");
    });
});

/* ================== Filterizr ================== */
var showItemsOnInit = 9;
var reloadItemsOnLoad = 3;

// active btns
var filterBtns = document.querySelectorAll('.filter-btn');

for (var i = 0; i < filterBtns.length; i++) {
    filterBtns[i].addEventListener("click", function() {

        [].forEach.call(filterBtns, function(btn){
            btn.classList.remove('active');
        });

        this.classList.add('active');
        var btnFilterAttribute = this.getAttribute('data-filter');
        
        // set local storage item
        localStorage.setItem('filterCat', btnFilterAttribute);
    });
}

const options = {
    animationDuration: 0.5, // in seconds
    callbacks: { 
        onFilteringStart: function() {},
        onFilteringEnd: function() { 
            // get local storage item
            var localItem = localStorage.getItem('filterCat');

            if( !localItem ) {
                localItem = 'all';
            }

            // if all is filtered
            if(localItem == 'all') {
                var items = document.querySelectorAll('.grid-item');
                prepareAllCat(items);

            } else {
                var btnLoad = document.querySelector('.btn-load');
                var foundCatItems = prepareCategories(localItem);
                var showItems = showItemsOnInit;
                var shownItems = showItems;
                var filterContainer = document.querySelector('.filter-container');
                var filterContainerHeight = parseInt(filterContainer.style.height, 10);
                var filterItemHeight = foundCatItems[0].offsetHeight + 30;
                var rowHeight = filterItemHeight * (shownItems/3); 
                var newContainerHeight = rowHeight - filterContainerHeight + 20; // +20 because of spacing to load more button
                var hideCounter = 0;

                if(Math.abs(newContainerHeight) > 800) {
                    filterContainer.style.maxHeight = Math.abs(newContainerHeight) + 60 + "px";
                } else {
                    filterContainer.style.maxHeight = rowHeight + 60 + "px";
                }

                if(window.innerWidth < 1024) {
                    filterContainer.style.maxHeight = "1350px";
                }
                if(window.innerWidth < 667) {
                    filterContainer.style.maxHeight = "2400px";
                }

                // hide button if items less then should be shown count
                if(foundCatItems.length <= showItemsOnInit) {
                    btnLoad.setAttribute("style", "display: none;");
                } else {
                    btnLoad.setAttribute("style", "display: inline-block;");
                }

                // reset hide class
                for (var i=0; i<foundCatItems.length; ++i) {
                    foundCatItems[i].classList.remove('filter-invisible');
                }

                // remove items > showItems
                for (i=showItemsOnInit; i<foundCatItems.length; ++i) {
                    // filterizr.removeItem(items[i]);
                    foundCatItems[i].classList.add('filter-invisible');
                }

                // reset btn element listeners 
                document.querySelector('.btn-load').outerHTML = document.querySelector('.btn-load').outerHTML;
                btnLoad = document.querySelector('.btn-load');

                // click event
                btnLoad.addEventListener("click", function(e){

                    e.preventDefault();
    
                    var incShownItems = shownItems + reloadItemsOnLoad;

                    // if cat is not filteredOut
                    if(foundCatItems[0].classList.contains('filteredOut') === false) {
                        // add more items
                        for (i=shownItems; i<incShownItems; ++i) {
                            if(typeof foundCatItems[i] !== "undefined") {
                                //filterizr.insertItem(items[i]);
                                foundCatItems[i].classList.remove('filter-invisible');
                            } 
                            hideCounter++;

                            var countHelper = foundCatItems.length - showItems;

                            if (hideCounter === countHelper){
                                btnLoad.setAttribute("style", "display: none;");
                            }
                        }
                    }   

                    shownItems = incShownItems;
                
                    // desktop
                    if(window.innerWidth > 1023) {
                        rowHeight = filterItemHeight * (shownItems/3);
                
                        // increase container height
                        newContainerHeight = rowHeight;
                        filterContainer.style.maxHeight = newContainerHeight + "px";
                    }

                    // tablet
                    if(window.innerWidth < 1024) { 
                        rowHeight = filterItemHeight * (shownItems/2) + 70;
                    
                        // increase container height
                        newContainerHeight = rowHeight;
                        filterContainer.style.maxHeight = newContainerHeight + "px";
                    }

                    // mobile
                    if(window.innerWidth < 667) { 
                        rowHeight = filterItemHeight * shownItems;
                    
                        // increase container height
                        newContainerHeight = rowHeight;
                        filterContainer.style.maxHeight = newContainerHeight + "px";
                    }
                });

            }

        },
        onShufflingStart: function() {},
        onShufflingEnd: function() {},
        onSortingStart: function() {},
        onSortingEnd: function() {}
    },
    controlsSelector: '', // Selector for custom controls
    delay: 0, // Transition delay in ms
    delayMode: 'progressive', // 'progressive' or 'alternate'
    easing: 'ease-out',
    filter: 'all', // Initial filter
    filterOutCss: { // Filtering out animation
      opacity: 0,
      //transform: 'scale(0.5)'
    },
    filterInCss: { // Filtering in animation
      opacity: 1,
      //transform: 'scale(1)'
    },
    gridItemsSelector: '.grid-item',
    gutterPixels: 20, // Items spacing in pixels
    layout: 'sameSize', // See layouts
    multifilterLogicalOperator: 'or',
    searchTerm: '',
    setupControls: true, // Should be false if controlsSelector is set 
    spinner: { // Configuration for built-in spinner
      enabled: false,
      fillColor: '#2184D0',
      styles: {
        height: '75px',
        margin: '0 auto',
        width: '75px',
        'z-index': 2,
      },
    },
}

let filterizr = null;
filterizr = new Filterizr('.filter-container', options);

document.addEventListener("DOMContentLoaded", function() {
    var items = document.querySelectorAll('.grid-item');
    var showItems = showItemsOnInit;
    var shownItems = showItems;
    var filterContainer = document.querySelector('.filter-container');
    var filterItemHeight = items[0].offsetHeight + 30;
    var rowHeight = filterItemHeight * (shownItems/3);
    var newContainerHeight = rowHeight - filterContainer.offsetHeight + 20; // +20 because of spacing to load more button
    filterContainer.style.maxHeight = newContainerHeight + "px";
   
    prepareAllCat(items);
});

window.addEventListener('resize', function() {
    
    if(window.innerWidth >= 1024) {
        filterContainer.style.maxHeight = newContainerHeight + "px";
    }
    if(window.innerWidth < 1024) {
        filterContainer.style.maxHeight = "1350px";
    }
    if(window.innerWidth < 667) {
        filterContainer.style.maxHeight = "2400px";
    }

});

function prepareAllCat(it) {
    
    var items = it;
    var itemsCount = items.length;
    var showItems = showItemsOnInit;
    var shownItems = showItems;
    var btnLoad = document.querySelector('.btn-load');
    var filterContainer = document.querySelector('.filter-container');
    var filterContainerHeight = parseInt(filterContainer.style.height, 10);
    var filterItemHeight = items[0].offsetHeight + 30;
    var rowHeight = filterItemHeight * (shownItems/3);
    var newContainerHeight = rowHeight + 20; // +20 because of spacing to load more button
    var i;
    var hideCounter = 0;

    filterContainer.style.maxHeight = Math.abs(newContainerHeight) + "px";

    if(window.innerWidth < 1024) {
        filterContainer.style.maxHeight = "1350px";
    }
    if(window.innerWidth < 667) {
        filterContainer.style.maxHeight = "2400px";
    }
    
    // activate loading btn 
    btnLoad.setAttribute("style", "display: inline-block;");

    // reset hide class
    for (var i=0; i<itemsCount; ++i) {
        items[i].classList.remove('filter-invisible');
    }

    if( itemsCount > showItems ) {
        // remove items > showItems
        for (i=showItems; i<itemsCount; ++i) {
            // filterizr.removeItem(items[i]);
            items[i].classList.add('filter-invisible');
        }
    }

    // reset btn element listeners 
    document.querySelector('.btn-load').outerHTML = document.querySelector('.btn-load').outerHTML;
    btnLoad = document.querySelector('.btn-load');

    btnLoad.addEventListener("click", function(e){
    
        e.preventDefault();

        var incShownItems = shownItems + reloadItemsOnLoad;

        // add more items
        for (i=shownItems; i<incShownItems; ++i) {
            if(typeof items[i] !== "undefined") {
                //filterizr.insertItem(items[i]);
                items[i].classList.remove('filter-invisible');
            }
            hideCounter++;

            var countHelper = itemsCount - showItems;

            if (hideCounter === countHelper){
                btnLoad.setAttribute("style", "display: none;");
            }
        }
    
        shownItems = incShownItems;
        
        // desktop
        if(window.innerWidth > 1023) {
            rowHeight = rowHeight + filterItemHeight;
        
            // increase container height
            newContainerHeight = rowHeight;
            filterContainer.style.maxHeight = newContainerHeight + "px";
        }

        // tablet
        if(window.innerWidth < 1024) { 
            rowHeight = filterItemHeight * (shownItems/2) + 70;
        
            // increase container height
            newContainerHeight = rowHeight;
            filterContainer.style.maxHeight = newContainerHeight + "px";
        }
        
        // mobile
        if(window.innerWidth < 667) { 
            rowHeight = filterItemHeight * shownItems;
        
            // increase container height
            newContainerHeight = rowHeight;
            filterContainer.style.maxHeight = newContainerHeight + "px";
        }

    });
}

/* Prepare category images */
function prepareCategories(cat) {
    
    var items = document.querySelectorAll('.grid-item');
    var itemsCount = items.length;
    var newItems = [];

    for (var i = 0; i < itemsCount; i++) {
        
        var itemCategory = items[i].getAttribute('data-category');

        if(cat == 1) {
            // add category 1
            if( itemCategory == 1 ) {
                newItems.push(items[i]);
            }
        } else if(cat == 2) {
            // add category 2
            if( itemCategory == 2 ) {
                newItems.push(items[i]);
            }
        }

    }

    return newItems;
}


/* ================== Menut Item is Active ==================*/
// Home
var entryMenuItem = document.getElementById('navElementHome');
entryMenuItem.classList.add("active");
window.addEventListener('scroll', function(){

    var entryMenuItem = document.getElementById('navElementHome');
    var entry = document.querySelector('#entry');
    var bounding = entry.getBoundingClientRect();

    if (
        bounding.top >= -320 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    ) { entryMenuItem.classList.add("active"); } else { entryMenuItem.classList.remove("active"); }

});

// Intro
window.addEventListener('scroll', function(){

    var introMenuItem = document.getElementById('navElementIntro');
    var intro = document.querySelector('#intro');
    var bounding = intro.getBoundingClientRect();

    if (
        bounding.top >= -100 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    ) { introMenuItem.classList.add("active"); } else { introMenuItem.classList.remove("active"); }

});

// Services
window.addEventListener('scroll', function(){

    var serviceMenuItem = document.getElementById('navElementServices');
    var services = document.querySelector('#services');
    var bounding = services.getBoundingClientRect();
    
    if (
        bounding.top <= 200 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom >= 100
    ) { serviceMenuItem.classList.add("active"); } else { serviceMenuItem.classList.remove("active"); }

});

// Gallery
window.addEventListener('scroll', function(){

    var galleryMenuItem = document.getElementById('navElementGallery');
    var gallery = document.querySelector('#gallery');
    var bounding = gallery.getBoundingClientRect();

    if (
        bounding.top <= 100 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom >= 100
    ) { galleryMenuItem.classList.add("active"); } else { galleryMenuItem.classList.remove("active"); }

});

// Contact
window.addEventListener('scroll', function(){

    var contactMenuItem = document.getElementById('navElementContact');
    var contact = document.querySelector('#contact');
    var bounding = contact.getBoundingClientRect();

    if (
        bounding.top <= 100 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom >= 100
    ) { contactMenuItem.classList.add("active"); } else { contactMenuItem.classList.remove("active"); }

});

/* ================== Cookie Notice ==================*/
new cookieNoticeJS({
 
    // Localizations of the notice message
    'messageLocales': {
      'it': 'Custom localized message'
    },

    // Localizations of the dismiss button text
    'buttonLocales': {
      'de': 'OK!'
    },

    // Position for the cookie-notifier (default=bottom)
    'cookieNoticePosition': 'bottom',

    // Shows the "learn more button (default=false)
    'learnMoreLinkEnabled': true,

    // The href of the learn more link must be applied if (learnMoreLinkEnabled=true)
    'learnMoreLinkHref': '/datenschutz.html',

    // Text for optional learn more button
    'learnMoreLinkText':{
        'de':'Mehr'
    },

    // The message will be shown again in X days
    'expiresIn': 30,

    // Dismiss button background color
    'buttonBgColor': '#ee2c3d',  

    // Dismiss button text color
    'buttonTextColor': '#fff',

    // Notice background color
    'noticeBgColor': '#000',

    // Notice text color
    'noticeTextColor': '#fff',

    // the learnMoreLink color (default='#009fdd')
    'linkColor': '#fff',

    // The target of the learn more link (default='', or '_blank')
    'linkTarget': '',

    // Print debug output to the console (default=false)
    'debug': false
 });

/* ================== Custom ==================*/

// scroll init
var scroll = new SmoothScroll('a[href*="#"]');

// Sticky Navbar
var nav = document.getElementById('home');
var sticky = nav.offsetTop;

window.onscroll = function () { 

    if (window.pageYOffset > sticky) {
        nav.classList.add("sticky")
    } else {
        nav.classList.remove("sticky");
    }
}

// Navbar
var toggle = document.querySelector('#burger-menu');
var overlay = document.querySelector('.menu-overlay');
var navigation = document.querySelector('.navigation');
var navigationElements = document.querySelectorAll('.nav-element');
var body = document.querySelector('body');

function setOverlay() {
    overlay.classList.add('active');
    navigation.classList.add('open');
    body.setAttribute("style", "overflow-y: hidden;");
}

function removeOverlay() {
    overlay.classList.remove('active');
    navigation.classList.remove('open');
    body.removeAttribute("style");
}

// check if toggle is open or closed
toggle.addEventListener("click", function(){  
    if (this.checked === true){ setOverlay(); }
    if (this.checked === false){ removeOverlay(); }
});

// event for all nav elements after click
Array.from(navigationElements).forEach((elem) => {
    elem.addEventListener("click", function(){
        removeOverlay();
        toggle.checked = false;
    });
});

// Form
var inputs = document.getElementsByClassName('form-element');

Array.from(inputs).forEach((input) => {

    input.addEventListener("blur", function(){
        if(input.value) {
            input.classList.add('hascontent');
        } else {
            input.classList.remove('hascontent');
        }
    });
});

var sendBtn = document.getElementById('send-form');

sendBtn.addEventListener("click", function(){
        
    // Form Elements
    var formName = document.getElementById('formName');
    var formEmail = document.getElementById('formEmail');
    var formMessage = document.getElementById('formMessage');
    var formPrivacy = document.getElementById('formCheckbox');

    // If Form is filled
    if(formName.value !== "" && formEmail.value !== "" && formMessage.value !== "" && formPrivacy.checked === true) {
        var contactSend = localStorage.getItem('contactSend');
        if(!contactSend){ localStorage.setItem('contactSend', 'true'); }
    } else {
        var contactNotSend = localStorage.getItem('contactNotSend');
        if(!contactNotSend){ localStorage.setItem('contactNotSend', 'true'); }
    }
});

// Message elements
var contactFlashMessage = document.getElementById('contact-response');
var contactFlashMessageError = document.getElementById('contact-response-error');

if(localStorage.getItem('contactSend')) {
    contactFlashMessage.setAttribute("style", "display: block;");
}

if(localStorage.getItem('contactNotSend')) {
    contactFlashMessageError.setAttribute("style", "display: block;");
}

contactFlashMessage.addEventListener("click", function(){
    this.removeAttribute("style");
    if(localStorage.getItem('contactSend')) {
        localStorage.removeItem('contactSend');
    }
});

contactFlashMessageError.addEventListener("click", function(){
    this.removeAttribute("style");
    if(localStorage.getItem('contactNotSend')) {
        localStorage.removeItem('contactNotSend');
    }
});

setTimeout(function(){
    if(localStorage.getItem('contactSend')) {
        localStorage.removeItem('contactSend');
    }
    if(localStorage.getItem('contactNotSend')) {
        localStorage.removeItem('contactNotSend');
    }
}, 10000);